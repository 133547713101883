import "./App.css";

function App() {
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+sHbEv7Sm7EUyNWQ1", "_blank");
    // window.open("https://t.me/your-telegram-channel", "_blank");
  };

  return (
    <>
      <div className="background-image"></div>
      <div class="overlay"></div>
      <div className="content" onClick={isMobileDevice() ? openTelegram : null}>
        <p
          style={{
            color: "#34a434b8",
            WebkitTextStroke: "0.5px white",
            fontSize: "1.5rem",
            fontFamily: "Alfa Slab One, serif",
            fontWeight: "400",
            animation: "colorChange 7s infinite alternate",
            border: "1px solid transparent",
            transition: "border-color 0.5s ease-in-out",
          }}
        >
          JOIN TELEGRAM NOW
        </p>

        <h1 style={{ diplay: "inline", marginBottom: "4px" }}>THAKRE</h1>
        <h1 style={{ display: "inline" }}>THE MUMBAI KING</h1>
        <div style={{ marginBottom: "3rem" }}></div>
        <p>🔘 𝗜𝗣𝗟 𝟮𝟬𝟮𝟯 𝗠𝗘 𝟳𝟰/𝟳𝟰 𝗣𝗔𝗦𝗦</p>
        <p>🔘 𝗣𝗦𝗟 𝟮𝟬𝟮𝟯 𝗠𝗘 𝟯𝟬/𝟯𝟰 𝗣𝗔𝗦𝗦.</p>
        <div style={{ marginBottom: "2rem" }}></div>
        <p
          style={{
            fontSize: "1rem",
            fontFamily: "Alfa Slab One, serif",
            fontWeight: "400",
            fontStyle: "normal",
            color: "#fff100",
            WebkitTextStroke: "0.2px #fff100",
          }}
        >
          🏆 100% FIXED IPL 2024 REPORT 🏆
        </p>
        <div style={{ marginBottom: "3rem" }}></div>
        <div style={{ marginBottom: "1.5rem" }}></div>
        <p>🔘 𝗙𝗜𝗫 ➪ 𝗧𝗢𝗦𝗦 + 𝗠𝗔𝗧𝗖𝗛 + 𝗦𝗘𝗦𝗦𝗜𝗢𝗡.</p>
        <p>🔘 𝗣𝗟𝗔𝗬 𝗕𝗘𝗧𝗦 𝗪𝗜𝗧𝗛 𝗠𝗨𝗠𝗕𝗔𝗜 𝗞𝗜𝗡𝗚 👑</p>
        <p>🔘 𝗧𝟮𝟬 𝗟𝗘𝗔𝗚𝗨𝗘𝗦 𝗞𝗔 𝗕𝗘𝗧𝗔𝗔𝗭 𝗕𝗔𝗔𝗗𝗦𝗛𝗔𝗛.</p>

        <div style={{ marginBottom: "2rem" }}></div>
        <p
          style={{
            fontSize: "1rem",
            WebkitTextStroke: "1px #ffffff",
            color: "black",
            fontFamily: "Ultra, serif",
            fontWeight: 400,
            fontStyle: "normal",
            animation: "blink 4s linear infinite",
          }}
        >
          👇 CLICK BELOW TO JOIN NOW 👇
        </p>
        <div style={{ marginBottom: "1.5rem" }}></div>
        <button >
          <div
            className="button"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <img
                src="logo.webp"
                style={{ height: "2rem", width: "2rem", display: "inline" }}
                alt="Logo"
              />
            </div>
            <div style={{ fontWeight: "bold" }}>&nbsp;&nbsp;JOIN TELEGRAM</div>
          </div>
        </button>
      </div>
    </>
  );
}

export default App;
